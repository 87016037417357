@import '/src/styles/utilities.scss';

.formNewsletter {
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: $space-36;
    align-items: center;
  }
}

.formNewsletter__details {
  display: flex;
  flex-direction: column;
  gap: $space-12;
  text-align: left;

  @include media-breakpoint-only(md) {
    margin-bottom: $space-24;
  }
}

.formNewsletter__submit {
  @include media-breakpoint-up(sm) {
    display: grid;
    grid-template-columns: auto auto;
    gap: $space-16;
    align-items: center;
  }
}
